<template>
  <b-form-group class="mb-0">
    <p class="d-flex align-items-center mb-1 ml-3">
      <span class="mr-1"> Thêm email đối tác:</span>
      <b-icon
        icon="question-circle"
        id="input-guest-email-lable"
        class="question-mark"
      ></b-icon>
      <b-tooltip
        target="input-guest-email-lable"
        triggers="hover"
        placement="right"
      >
        <span class="text-left">
          Đối với đối tác không có trong hệ thống, bạn có thể nhập email vào ô
          dưới đây để thêm vào danh sách tham dự cuộc họp cũng như gửi email
          thông báo.
          <br />
          <br />
          Tuy nhiên, <b class="text-primary">đối với những lần sau</b> hệ thống
          sẽ tự cập nhật thông tin người dùng và lưu vào ô đối tác ở phần lựa
          chọn ('Thêm người tham dự cuộc họp') bên dưới.
        </span>
      </b-tooltip>
    </p>
    <b-form-tags
      invalid-tag-text="Email không đúng định dạng vui lòng kiểm tra lại"
      :tag-validator="tagValidator"
      :state="state"
      input-id="tags-remove-on-delete"
      :input-attrs="{ 'aria-describedby': 'tags-remove-on-delete-help' }"
      v-model="_value"
      separator=" "
      :placeholder="guestEmail.length ? '' : 'Nhập email đối tác'"
      remove-on-delete
      duplicate-tag-text="Có 1 hoặc nhiều email đang bị trùng lặp"
      size="sm"
      input-class="guest-email-input"
      tag-class="email-tag"
      add-button-text="thêm"
    ></b-form-tags>
  </b-form-group>
</template>

<script>
export default {
  name: "guest-email-input",
  //   props: ["roomInfo", "week"],
  //   mixins: [validationMixin],

  data() {
    return {
      guestEmail: [],
      emailRegex: /^[^\s@]+@[^\s@]+\.[^\s@]+$/i,
    };
  },
  props: {
    value: {
      default: () => [],
      required: true,
    },
  },
  model: {
    prop: "value",
    event: "valueChange",
  },
  computed: {
    state() {
      // Overall component validation state
      return this.dirty ? this.dirty.error : null;
    },
    _value: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("valueChange", value);
      },
    },
  },
  methods: {
    tagValidator(tag) {
      // Individual tag validator function
      return this.emailRegex.test(tag);
    },
  },
};
</script>

<style>
.text-truncate {
  display: block;
  margin: auto;
  padding: 2px 3px;
}
.email-tag {
  display: flex !important;
  align-items: center;
  font-weight: 400;
  font-size: 1rem;
  margin: 2px;
  padding: 0 0.5rem;
}
.guest-email-input {
  padding: 0;
}
.b-form-tags {
  padding: 0.555rem;
}
.tooltip-inner {
  text-align: left;
}
</style>