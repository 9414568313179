var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.meetingInfo)?_c('div',[_c('b-card',[_c('div',{staticClass:"card-header mb-0"},[_c('h1',[_vm._v("Thông Tin Chi Tiết Cuộc Họp")])]),_c('b-tabs',{staticClass:"mt-5",attrs:{"pills":""}},[_c('div',{staticClass:"d-flex mt-5"},[_c('div',{staticClass:"d-flex flex-column w-50 mr-6"},[_c('h5',{staticClass:"h5-color"},[_vm._v("Người tạo cuộc họp:")]),_c('p',{staticClass:"fonsize-14"},[_vm._v(_vm._s(_vm.meetingInfo.user.fullname))]),_c('h5',{staticClass:"h5-color"},[_vm._v("Tiêu đề cuộc họp:")]),_c('p',{staticClass:"fonsize-14"},[_vm._v(" "+_vm._s(_vm.meetingInfo.meetingTitle ? _vm.meetingInfo.meetingTitle : "không có tiêu đề")+" ")]),_c('h5',{staticClass:"h5-color"},[_vm._v("Nội dung cuộc họp:")]),_c('p',{staticClass:"fonsize-14"},[_vm._v(" "+_vm._s(_vm.meetingInfo.meetingContent ? _vm.meetingInfo.meetingContent : "không có nội dung cuộc họp")+" ")]),_c('h5',{staticClass:"h5-color"},[_vm._v("Ngày:")]),_c('p',{staticClass:"fonsize-14"},[_vm._v(_vm._s(_vm.date))]),_c('h5',{staticClass:"h5-color"},[_vm._v("Thời Gian:")]),_c('p',{staticClass:"fonsize-14"},[_vm._v(_vm._s(_vm.startingTime)+" - "+_vm._s(_vm.endingTime))]),_c('h5',{staticClass:"h5-color"},[_vm._v("Số người tham dự:")]),_c('p',{staticClass:"fonsize-14"},[_vm._v(_vm._s(_vm.totalPeopelInMeeting)+" người")]),(_vm.meetingInfo.reason)?_c('div',[_c('h5',{staticClass:"h5-color"},[_vm._v("Lý do hủy cuộc họp:")]),_c('p',{staticClass:"fonsize-14"},[_vm._v(_vm._s(_vm.meetingInfo.reason))])]):_vm._e()]),_c('div',{staticClass:"w-100",staticStyle:{"marginleft":"100px"}},[(
              (this.currentUser.id === this.meetingInfo?.userId &&
                _vm.meetingInfo?.status === 'comingUp') ||
              (this.currentUser.role === 'admin' &&
                _vm.meetingInfo?.status === 'comingUp')
            )?_c('div',[_c('p',{staticClass:"d-flex align-items-end"},[_c('guest-email-input',{staticStyle:{"flex-grow":"1"},model:{value:(_vm.guestMail),callback:function ($$v) {_vm.guestMail=$$v},expression:"guestMail"}}),_c('button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"btn btn-light ml-2 mr-2",staticStyle:{"height":"34.65px"},attrs:{"type":"button"},on:{"click":_vm.addNewGuest}},[_c('i',{staticClass:"fa fa-plus",staticStyle:{"color":"#4caf50"}})])],1),_c('InputSendEmailVue',{attrs:{"meetingInfo":_vm.meetingInfo,"seats":_vm.meetingInfo?.room.seats,"meetingArray":[_vm.meetingId * 1],"getListOfUserPressentInTheMeeting":_vm.getListOfUserPressentInTheMeeting,"userListInParticipant":_vm.userList},on:{"handleSendEmail":_vm.handleSendEmail}})],1):_vm._e(),_c('b-table',{attrs:{"striped":"","hover":"","items":_vm.userList,"fields":_vm.visibleFilter},scopedSlots:_vm._u([{key:"cell(action)",fn:function(row){return [(_vm.checkUserListHasMe(row.item))?_c('div',{staticClass:"text-center d-flex justify-content-center"},[(row.item.email)?_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"cursor_pointer",class:{
                    hiddenOrShowButtonInDetailMeeting: _vm.hiddenOrShowButtonInDetailMeeting,
                  },attrs:{"id":`loading-${row.index}`,"title":"Gửi email thông báo"},on:{"click":function($event){return _vm.debounceHandleSendEmail(row)}}},[_c('i',{staticClass:"fa fa-paper-plane text-success",class:_vm.faPlaneObject})]):_vm._e(),(_vm.currentUser.id !== _vm.meetingInfo.userId)?_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"cursor_pointer ml-5",attrs:{"title":"Rời khỏi cuộc họp"},on:{"click":function($event){return _vm.handleConfirmUserLeaveMeeting(row)}}},[_c('i',{staticClass:"fa fa-sign-out-alt text-danger fs-20"})]):_vm._e()]):_c('div',{staticClass:"d-flex justify-content-center"},[(row.item.email)?_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"cursor_pointer mr-5",class:{ hiddenOrShowButtonInDetailMeeting: _vm.hiddenOrShowButtonInDetailMeeting },attrs:{"id":`loading-${row.index}`,"title":"Gửi email thông báo"},on:{"click":function($event){return _vm.debounceHandleSendEmail(row)}}},[_c('i',{staticClass:"fa fa-paper-plane text-success",class:_vm.faPlaneObject})]):_vm._e(),_c('div',{staticClass:"cursor_pointer",class:_vm.hiddenOrShowButtonInDetailMeeting,on:{"click":function($event){return _vm.handleConfirmDelete(row)}}},[_c('i',{staticClass:"fa fa-trash text-danger"})])])]}},{key:"cell(departmentName)",fn:function(row){return [_c('div',{staticClass:"text-center"},[_c('p',[_vm._v(_vm._s(row.item.departmentName))])])]}},{key:"cell(userId)",fn:function(row){return [_c('div',{staticClass:"text-center"},[_c('p',[_vm._v(_vm._s(row.item.userId))])])]}},{key:"cell(email)",fn:function(row){return [_c('div',{staticClass:"text-center"},[_c('p',[_vm._v(" "+_vm._s(row.item.email ? row.item.email : "không có email")+" ")])])]}},{key:"cell(fullname)",fn:function(row){return [_c('p',{staticClass:"text-center",class:{
                  'text-primary': row.item.userId === _vm.currentUser.id,
                }},[_vm._v(" "+_vm._s(row.item.fullname)+" ")])]}},{key:"cell(announced)",fn:function(row){return [_c('div',{staticClass:"text-center"},[(row.item.announced === 1)?_c('p',[_c('i',{staticClass:"fa fa-check text-success"})]):_vm._e(),(row.item.announced === 0)?_c('p',[_c('i',{staticClass:"fa fa-times text-danger"})]):_vm._e()])]}}],null,false,1474646189)})],1)])])],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }