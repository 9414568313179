<template>
  <div v-if="meetingInfo">
    <b-card>
      <div class="card-header mb-0">
        <h1>Thông Tin Chi Tiết Cuộc Họp</h1>
      </div>
      <b-tabs class="mt-5" pills>
        <div class="d-flex mt-5">
          <!-- thông tin chi tiết cuộc họp -->
          <div class="d-flex flex-column w-50 mr-6">
            <h5 class="h5-color">Người tạo cuộc họp:</h5>
            <p class="fonsize-14">{{ meetingInfo.user.fullname }}</p>
            <h5 class="h5-color">Tiêu đề cuộc họp:</h5>
            <p class="fonsize-14">
              {{
                meetingInfo.meetingTitle
                  ? meetingInfo.meetingTitle
                  : "không có tiêu đề"
              }}
            </p>
            <h5 class="h5-color">Nội dung cuộc họp:</h5>
            <p class="fonsize-14">
              {{
                meetingInfo.meetingContent
                  ? meetingInfo.meetingContent
                  : "không có nội dung cuộc họp"
              }}
            </p>
            <h5 class="h5-color">Ngày:</h5>
            <p class="fonsize-14">{{ date }}</p>
            <h5 class="h5-color">Thời Gian:</h5>
            <p class="fonsize-14">{{ startingTime }} - {{ endingTime }}</p>
            <h5 class="h5-color">Số người tham dự:</h5>
            <p class="fonsize-14">{{ totalPeopelInMeeting }} người</p>
            <div v-if="meetingInfo.reason">
              <h5 class="h5-color">Lý do hủy cuộc họp:</h5>
              <p class="fonsize-14">{{ meetingInfo.reason }}</p>
            </div>
          </div>

          <!-- bảng người tham dự có mặt trong cuộc họp -->
          <div class="w-100" style="marginleft: 100px">
            <!-- //! input send email -->
            <div
              v-if="
                (this.currentUser.id === this.meetingInfo?.userId &&
                  meetingInfo?.status === 'comingUp') ||
                (this.currentUser.role === 'admin' &&
                  meetingInfo?.status === 'comingUp')
              "
            >
              <p class="d-flex align-items-end">
                <!-- @get-guest-email="getGuestEmail" -->
                <!-- @get-guest-email-input-emit="getGuestEmailInputEmit" -->
                <guest-email-input style="flex-grow: 1" v-model="guestMail" />
                <button
                  v-b-tooltip.hover
                  type="button"
                  class="btn btn-light ml-2 mr-2"
                  style="height: 34.65px"
                  @click="addNewGuest"
                >
                  <i class="fa fa-plus" style="color: #4caf50"></i>
                </button>
              </p>

              <InputSendEmailVue
                v-bind:meetingInfo="meetingInfo"
                v-bind:seats="meetingInfo?.room.seats"
                v-on:handleSendEmail="handleSendEmail"
                v-bind:meetingArray="[meetingId * 1]"
                :getListOfUserPressentInTheMeeting="
                  getListOfUserPressentInTheMeeting
                "
                v-bind:userListInParticipant="userList"
              />
            </div>
            <b-table striped hover :items="userList" :fields="visibleFilter">
              <template v-slot:cell(action)="row">
                <div
                  v-if="checkUserListHasMe(row.item)"
                  class="text-center d-flex justify-content-center"
                >
                  <div
                    :id="`loading-${row.index}`"
                    v-if="row.item.email"
                    v-b-tooltip.hover
                    title="Gửi email thông báo"
                    class="cursor_pointer"
                    :class="{
                      hiddenOrShowButtonInDetailMeeting,
                    }"
                    v-on:click="debounceHandleSendEmail(row)"
                  >
                    <i
                      :class="faPlaneObject"
                      class="fa fa-paper-plane text-success"
                    ></i>
                  </div>

                  <div
                    v-if="currentUser.id !== meetingInfo.userId"
                    v-b-tooltip.hover
                    title="Rời khỏi cuộc họp"
                    class="cursor_pointer ml-5"
                    v-on:click="handleConfirmUserLeaveMeeting(row)"
                  >
                    <i class="fa fa-sign-out-alt text-danger fs-20"></i>
                  </div>
                </div>

                <div v-else class="d-flex justify-content-center">
                  <div
                    :id="`loading-${row.index}`"
                    v-if="row.item.email"
                    v-b-tooltip.hover
                    title="Gửi email thông báo"
                    class="cursor_pointer mr-5"
                    :class="{ hiddenOrShowButtonInDetailMeeting }"
                    v-on:click="debounceHandleSendEmail(row)"
                  >
                    <i
                      :class="faPlaneObject"
                      class="fa fa-paper-plane text-success"
                    ></i>
                  </div>

                  <div
                    :class="hiddenOrShowButtonInDetailMeeting"
                    class="cursor_pointer"
                    v-on:click="handleConfirmDelete(row)"
                  >
                    <i class="fa fa-trash text-danger"></i>
                  </div>
                </div>
              </template>

              <template v-slot:cell(departmentName)="row">
                <div class="text-center">
                  <p>{{ row.item.departmentName }}</p>
                </div>
              </template>

              <template v-slot:cell(userId)="row">
                <div class="text-center">
                  <p>{{ row.item.userId }}</p>
                </div>
              </template>

              <template v-slot:cell(email)="row">
                <div class="text-center">
                  <p>
                    {{ row.item.email ? row.item.email : "không có email" }}
                  </p>
                </div>
              </template>

              <template v-slot:cell(fullname)="row">
                <p
                  class="text-center"
                  :class="{
                    'text-primary': row.item.userId === currentUser.id,
                  }"
                >
                  {{ row.item.fullname }}
                </p>
              </template>

              <template v-slot:cell(announced)="row">
                <div class="text-center">
                  <p v-if="row.item.announced === 1">
                    <i class="fa fa-check text-success"></i>
                  </p>
                  <p v-if="row.item.announced === 0">
                    <i class="fa fa-times text-danger"></i>
                  </p>
                </div>
              </template>
            </b-table>
          </div>
        </div>
      </b-tabs>
    </b-card>
  </div>
</template>
<script>
// import debounce from "lodash/debounce";
import {
  successNotification,
  errorNotification,
} from "@/core/helpers/notification";
import moment from "moment";
import ApiService from "@/core/services/api.service.js";
import InputSendEmailVue from "../input/InputSendEmail.vue";
import { mapGetters } from "vuex";
import { RELOAD_COUNT } from "@/core/services/store/store";

import GuestEmailInput from "@/view/content/input/GuestEmailInput.vue";

export default {
  name: "form-detail-meeting",
  data() {
    return {
      faPlaneObject: {
        "d-none": false,
        "d-block": true,
      },

      loadingObject: {
        "d-none": false,
        "loading-button": true,
      },
      test: null,
      items: [],
      fields: [
        {
          key: "userId",
          label: "ID",
          visible: true,
          thStyle: { textAlign: "center" },
        },
        {
          key: "fullname",
          label: "Tên",
          visible: true,
          thStyle: { textAlign: "center" },
        },
        {
          key: "email",
          label: "email",
          visible: true,
          thStyle: { textAlign: "center" },
        },
        {
          key: "departmentName",
          label: "Phòng Ban",
          visible: true,
          thStyle: { textAlign: "center" },
        },
        {
          key: "announced",
          label: "Đã Gửi Mail Thông Báo",
          visible: true,
          thStyle: { textAlign: "center" },
        },
        {
          key: "action",
          label: "Thao Tác",
          visible: true,
          thStyle: { textAlign: "center" },
        },
      ],
      meetingId: null,
      meetingInfo: null,
      userList: [],
      totalPeopelInMeeting: 0,
      hiddenOrShowButtonInDetailMeeting: "",
      guestEmail: [],
      guestEmailInputEmit: null,
      guestMail: [],
    };
  },

  components: {
    InputSendEmailVue,
    GuestEmailInput,
  },

  methods: {
    handleHiddenOrShowButtonInDetailMeeting() {
      if (!this.meetingInfo) return;
      if (
        (this.currentUser.id !== this.meetingInfo.userId &&
          this.currentUser.role === "user") ||
        this.meetingInfo.status !== "comingUp"
      )
        this.hiddenOrShowButtonInDetailMeeting = "d-none";
    },

    checkUserListHasMe(item) {
      if (item.userId === this.currentUser.id) return true;
    },

    async handleSendEmail(row) {
      await ApiService.post("/bookinginfo/sendEmail", {
        userArray: [row.item.id],
        bookingId: this.meetingInfo.id,
        userIdLogin: this.currentUser.id,
      })
        .then(() => {
          const bookingId = this.meetingInfo.id;

          ApiService.put("/bookinginfo/checkSendEmail", {
            userArray: [row.item.id],
            bookingId,
          }).then(() => {
            this.getListOfUserPressentInTheMeeting();
          });
          successNotification("Gửi mail thành công");
        })
        .catch(() => {
          errorNotification("Không thất bại");
        });
    },

    takeIdOnParams() {
      const id = this.$route.params.id;
      this.meetingId = id;
    },
    getDetailBooking() {
      ApiService.get(`/meeting/readMeetingDetails/${this.meetingId}`).then(
        (db) => {
          const { result } = db.data;
          this.meetingInfo = result;
        }
      );
    },
    async getListOfUserPressentInTheMeeting() {
      await ApiService.get(
        "/meeting/listOfUserInTheMeeting",
        this.meetingId
      ).then((db) => {
        const { result } = db.data;

        this.totalPeopelInMeeting = result.length;
        this.userList = result.map((item) => {
          return {
            ...item,
            email: item.label,
            userId: item.id,
          };
        });

        this.handleHiddenOrShowButtonInDetailMeeting();
      });
    },

    async handleDeleteUserOutOfMeeting(row) {
      const meetingId = this.meetingId;

      await ApiService.put(`/meeting/deleteUserOutOfMeeting/${meetingId}`, {
        arrUserId: row.item?.userId,
        userIdLogin: this.currentUser.id,
        bookingId: this.meetingInfo.id,
      })
        .then(() => {
          successNotification("xóa thành công");
          this.getListOfUserPressentInTheMeeting();
        })
        .catch(() => {
          errorNotification("xóa thất bại");
        });

      await ApiService.delete(
        `/bookinginfo/deleteGuestOutOfMeeting?userId=${row.item?.userId}&bookingId=${this.meetingInfo.id}`
      );
    },
    handleConfirmDelete(row) {
      this.$bvModal
        .msgBoxConfirm("Bạn có chắc muốn xóa người dùng này ?", {
          title: "Xác Nhận Xóa",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Có",
          cancelTitle: "Không",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.handleDeleteUserOutOfMeeting(row);
          }
        })
        .catch(() => {});
    },
    handleConfirmUserLeaveMeeting(row) {
      this.$bvModal
        .msgBoxConfirm("Bạn có chắc muốn xóa người dùng này ?", {
          title: "Xác Nhận Xóa",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Có",
          cancelTitle: "Không",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.handleUserLeaveMeeting(row);
          }
        });
    },

    async handleUserLeaveMeeting(row) {
      const meetingId = this.meetingId;
      ApiService.put(`/meeting/userLeaveMeeting/${meetingId}`, {
        userId: row.item?.userId,
        userIdLogin: this.currentUser.id,
      })
        .then(() => {
          successNotification("Rời khỏi cuộc họp thành công");
          this.getListOfUserPressentInTheMeeting();
        })
        .catch(() => {
          errorNotification("Rời khỏi cuộc họp thất bại");
        });

      await ApiService.delete(
        `/bookinginfo/deleteGuestOutOfMeeting?userId=${row.item?.userId}&bookingId=${this.meetingInfo.id}`
      );
    },
    async debounceHandleSendEmail(row) {
      this.faPlaneObject["d-none"] = true;
      this.faPlaneObject["d-block"] = false;

      document
        .getElementById(`loading-${row.index}`)
        .classList.add("loading", "disabled");

      await this.handleSendEmail(row);

      document
        .getElementById(`loading-${row.index}`)
        .classList.remove("loading", "disabled");

      this.faPlaneObject["d-none"] = false;
      this.faPlaneObject["d-block"] = true;
    },
    hideOrShowAction() {
      if (this.meetingInfo.status === "comingUp") {
        for (const item of this.fields) {
          if (item.key === "action") return (item.visible = true);
        }
      } else {
        for (const item of this.fields) {
          if (item.key === "action") return (item.visible = false);
        }
      }
    },
    async addNewGuest() {
      if (this.guestMail.length) {
        const userArray = [];

        const { data } = await ApiService.post("/bookingInfo/addGuestAccount", {
          guestEmail: this.guestMail,
        });
        data.data.forEach((guest) => userArray.push(guest.id));
        await ApiService.post("/meeting/addParticipantsToMeeting", {
          meetingArray: [this.meetingId],
          userArray,
        });

        await ApiService.put("/bookinginfo/inviteMoreGuest", {
          userArray,
          eventId: this.meetingInfo.eventId,
        });
        this.$store.dispatch(RELOAD_COUNT);
        this.guestMail = [];
      }
    },
  },
  computed: {
    ...mapGetters(["currentUser", RELOAD_COUNT]),
    date() {
      const date = this.meetingInfo?.startingTime;
      return moment(date).format("DD/MM/YYYY");
    },
    startingTime() {
      const startingTime = this.meetingInfo?.startingTime;
      return moment(startingTime).format("HH:mm");
    },
    endingTime() {
      const endingTime = this.meetingInfo?.endingTime;
      return moment(endingTime).format("HH:mm");
    },
    attendanceTime() {
      const startingTime = this.meetingInfo?.startingTime;
      const endingTime = this.meetingInfo?.endingTime;

      return endingTime - startingTime;
    },
    visibleFilter() {
      this.hideOrShowAction();
      return this.fields.filter((item) => item.visible === true);
    },
  },

  async mounted() {
    this.takeIdOnParams();
    await this.getDetailBooking();
    await this.getListOfUserPressentInTheMeeting();
  },

  watch: {
    [RELOAD_COUNT]: {
      immediate: true,
      async handler() {
        this.takeIdOnParams();
        await this.getDetailBooking();
        await this.getListOfUserPressentInTheMeeting();
      },
    },
  },
};
</script>

<style scoped>
.fonsize-14 {
  font-size: 14px;
}
.h5-color {
  color: #429cff;
}

.tooltip {
  opacity: 1;
}
.fs-20 {
  font-size: 20px;
}

.cursor_pointer {
  cursor: pointer;
}

.translate {
  transform: translate(-16px, 1px);
}

@mixin center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loading {
  @include center;
  background-color: transparent;
  border: 4px solid #aba8af;
  border-top-color: green;
  user-select: none;
  font-size: 0;
  outline: none;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  animation: rotate 0.8s linear infinite;
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
